/**
 * viewportに表示されるコンテンツに応じてターゲット要素のデータ属性を変更する
 * @module itemOvserve
 * @param {Object} object セレクター情報オブジェクト
 * @param {string} object.observedElements 監視する要素のセレクター
 * @return {void} 返り値なし
 */
export var itemOvserve = function (selecter) {
    var observedElements = document.querySelectorAll(selecter);
    var myOptions = {
        rootMargin: '-10% 0px -10% 0px',
        threshold: 0
    };
    // IntersectionObserverコールバック
    var myCallback = function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                entry.target.setAttribute('data-inview', 'visible');
            }
        });
    };
    // インスタンス生成
    var myObserve = new IntersectionObserver(myCallback, myOptions);
    // 監視の開始
    observedElements.forEach(function (target) {
        myObserve.observe(target);
    });
};
