import { UAParser } from 'ua-parser-js';
/**
 * cms取得モジュール ie以外
 * @module smoothScroll
 * @param {string} selector スムーススクロールを発火させるアンカーセレクター
 * @return {void} 返り値なし
 */
/*
 *  スムーススクロール
 * --------------------------------------------------
 */
export var smoothScroll = function (selector) {
    var parser = new UAParser();
    var result = parser.getResult();
    if (result.browser.name === 'Safari' || result.browser.name === 'IE') {
        var nodeList = document.querySelectorAll(selector);
        var filename_1 = location.href.match('.+/(.+?)([?#;].*)?$')[1];
        nodeList.forEach(function (element) {
            element.addEventListener('click', function () {
                // event.preventDefault();
                if (element.getAttribute('href') === '#' ||
                    element.getAttribute('href') === filename_1 + "#") {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: 0
                    });
                }
                else {
                    var target = document.querySelector(element.hash);
                    if (target !== null) {
                        var amount = target.getBoundingClientRect().top;
                        window.scrollTo({
                            behavior: 'smooth',
                            top: amount
                        });
                    }
                }
            });
        });
    }
    else {
        document.documentElement.style.scrollBehavior = 'smooth';
    }
};
