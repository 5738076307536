/**
 * viewportに表示されるコンテンツに応じてターゲット要素のデータ属性を変更する
 * @module areaOvserve
 * @param {Object} object セレクター情報オブジェクト
 * @param {string} object.grantedElement data属性値を付与する要素のセレクター
 * @param {string} object.observedElements 監視する要素のセレクター
 * @return {void} 返り値なし
 */
export var areaOvserve = function (object) {
    var grantedElements = document.querySelectorAll(object.grantedElement);
    var observedElements = document.querySelectorAll(object.observedElements);
    // const grantedElement = object.grantedElement
    //   ? document.querySelectorAll(object.grantedElement)!
    //   : document.body;
    // eslint-disable-next-line max-len
    // const observedElements = document.querySelectorAll(object.observedElements);
    // if (observedElements.length) {
    //   grantedElement.setAttribute('data-viewarea', observedElements[0].id);
    // }
    // IntersectionObserverオプション
    // const myOptions = {
    //   rootMargin: '-50% 0px -50% 0px',
    //   threshold: 0
    // };
    var myOptions = {
        rootMargin: '-10% 0px -10% 0px',
        threshold: 0
    };
    // IntersectionObserverコールバック
    var myCallback = function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                grantedElements.forEach(function (element) {
                    element.setAttribute('data-inview', 'visible');
                });
                // grantedElement.setAttribute('data-viewarea', entry.target.id);
            }
        });
    };
    // インスタンス生成
    var myObserve = new IntersectionObserver(myCallback, myOptions);
    // 監視の開始
    observedElements.forEach(function (target) {
        myObserve.observe(target);
    });
};
